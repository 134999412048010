<template>
	<div
		class="wrap__content"
		v-bind:class="{ 'wrap__content--preloader': !applications || !$store.state.userObject }"
	>
		<v-preloader v-if="!$store.state.userObject" :message="errorMessage"></v-preloader>

		<div class="container" v-if="$store.state.userObject">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['TradeManagementSupervisor']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.trade_list') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														v-model="filters.iin_bin"
														type="text"
														value=""
														name="filter_iin_bin"
														:placeholder="$t('headers.iin_bin')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>

											<div class="table__filter--order">
												<region
													:modelValue="region_id"
													:name="'region_id'"
													:key="region_id"
													:placeholder="'Все...'"
													@update:modelValue="region_id = $event"
												>
												</region>
											</div>
											<!--
                      <div class="table__filter--order">
                        <multiselect
                            class="select__status"
                            v-model="filterCategories"
                            :options="orderList"
                            label="name"
                            :clearOnSelect="false"
                            :clear-on-select="false"
                            :placeholder="$t('placeholder.choose')"
                        >
                          <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                          <template slot="noResult">{{ $t('components.not_found') }}</template>
                        </multiselect>
                      </div>
                      -->
										</div>
										<div class="table__filter--right"></div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:server-items-length="totalItems"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="pagination"
									>
										<template v-slot:item.first_name="{ item }">
											<router-link
												v-if="$store.getters.userPermission(['read_document_all'])"
												:to="
													'/' + $i18n.locale + '/account/trade-list/' + item.id + '/user-profile'
												"
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: 'Профиль субъекта',
													class: ['tooltip__btn'],
												}"
											>
												<template v-if="item.name_company">{{ item.name_company }}<br /></template>
												<template v-else
													>{{ item.first_name }} {{ item.last_name }}
													{{ item.middle_name }}</template
												>
											</router-link>
										</template>
										<template v-slot:item.regions="{ item }">
											<div>
												{{
													item.regions
														.map(
															(region) =>
																region.region[`title${$i18n.locale == 'ru' ? '' : '_kz'}`],
														)
														.join(', ')
												}}
											</div>
										</template>
										<template v-slot:item.created_at="{ item }">
											<div>
												{{ new Date(item.created_at).toLocaleDateString() }}
											</div>
										</template>

										<template v-slot:item.action="{ item }">
											<router-link
												v-if="$store.getters.userPermission(['read_document_all'])"
												:to="
													'/' + $i18n.locale + '/account/trade-list/' + item.id + '/user-profile'
												"
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: $t('labels.subject_profile'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.471 16.647v-1.5a3 3 0 00-3-3h-6a3 3 0 00-3 3v1.5M9.471 9.147a3 3 0 100-6 3 3 0 000 6z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<router-link
												v-if="$store.getters.userPermission(['read_document_all'])"
												:to="'/' + $i18n.locale + '/account/trade-list/' + item.id + '/list'"
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: $t('labels.view_journal'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 12l3-3-3-3M6 9h6" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'

import Region from '@/components/components/Inputs/region'

let timerId

export default {
	components: { Region },
	props: {
		user: {
			type: Object,
		},
	},
	data() {
		return {
			urlApi: urlApi,
			region_id: null,
			applications: null,
			totalItems: null,
			errorMessage: {
				status: null,
				text: null,
				messages: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,
			filters: {
				iin_bin: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),
			headers: [
				{ text: 'Id', value: 'id', width: '50px' },
				{ text: this.$t('headers.fio'), value: 'first_name', width: '23vw' },
				{ text: this.$t('headers.iin_bin'), value: 'iin_bin', width: '10vw' },
				{ text: this.$t('headers.regions'), value: 'regions', width: '10vw' },
				{ text: this.$t('headers.register_at'), value: 'created_at', width: '10vw' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],

			fileDocuments: {
				file: null,
				fileName: '',
				fileClass: '',
			},
		}
	},
	computed: {
		filteredlist() {
			return this.applications || []
		},
	},
	watch: {
		'filters.iin_bin'() {
			if (timerId) clearTimeout(timerId)

			if (this.filters.iin_bin.trim().length === 12 || this.filters.iin_bin.trim().length === 0) {
				timerId = setTimeout(this.apiGetUserDocumentList, 50)
			}
		},
		region_id() {
			this.apiGetUserDocumentList()
		},
	},
	methods: {
		handleFileUpload() {
			this.message.status = null
			this.message.text = null
			this.message.texts = null
			this.errorMessage = {
				status: null,
				text: null,
				messages: null,
			}
			this.fileDocuments.file = this.$refs.fileDocuments.files[0]
			this.fileDocuments.fileName = this.$refs.fileDocuments.files[0].name
			this.fileDocuments.fileClass = 'add_tourist--success'
		},
		pagination(options) {
			this.apiGetUserDocumentList(options.page, options.itemsPerPage)
		},
		apiGetUserDocumentList(page = 1, per_page = 10) {
			let params = this.region_id ? [this.region_id] : []
			this.$store.state.userObject.regions?.find((element) => {
				params.push(element.region_id)
			})
			api
				.get(`user/region/list`, {
					params: {
						regions: params,
						...(this.filters.iin_bin ? { 'filter[search]': this.filters.iin_bin } : {}),
						page,
						per_page,
					},
				})
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						this.totalItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},

	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
<style>
header .header__row {
	display: flex;
}
</style>
